import type { FC, ReactNode } from 'react';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { NumberInput } from '@sravni/react-design-system';

import { RUSSIA_AMOUNT_FIELD_SETTINGS } from '@src/constants/amountSettings';
import { validateByParams } from '@src/helpers/validate';

interface IProps {
  className?: string;
  value?: number | null;
  name: string;
  label?: string;
  placeholder?: string;
  max?: number;
  error?: string;
  onChange: (value: number | undefined, name: string) => void;
  format?: string | ((value: string) => string);
  postfix?: string;
  defaultValue?: number;
  icon?: ReactNode;
  autoFocus?: boolean;
}

const THOUSAND_SEPARATOR = ' ';

export const AmountControl: FC<IProps> = ({
  value,
  name,
  label,
  max = RUSSIA_AMOUNT_FIELD_SETTINGS.max,
  placeholder,
  onChange,
  format,
  autoFocus,
  icon,
  postfix,
  defaultValue,
  className,
  error,
  ...props
}) => {
  const validate = useMemo(() => validateByParams({ max }), [max]);
  const validatedValue = validate(value);

  const handleChange = useRef(onChange);

  useEffect(() => {
    // валидация: если в query-параметрах кто-то вручную исправит значение
    if (validatedValue !== value) {
      handleChange.current(validatedValue, name);
    }
  }, [name, validatedValue, value]);

  const handleChangeAmountInput = useCallback(
    (value = 0) => {
      handleChange.current(validate(value), name);
    },
    [name, validate],
  );

  return (
    <NumberInput
      label={error || label}
      className={className}
      placeholder={placeholder}
      value={validatedValue}
      postfix={postfix}
      min={0}
      max={max}
      defaultValue={defaultValue}
      icon={icon}
      maxLength={RUSSIA_AMOUNT_FIELD_SETTINGS.maxLength}
      // @ts-ignore
      format={format}
      autoFocus={autoFocus}
      invalid={Boolean(error)}
      thousandSeparator={THOUSAND_SEPARATOR}
      inputMode="numeric"
      onChange={handleChangeAmountInput}
      {...props}
    />
  );
};

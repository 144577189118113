import type { RefObject, SyntheticEvent } from 'react';
import React, { memo } from 'react';

import { Typography } from '@sravni/react-design-system';
import type { ButtonProps } from '@sravni/react-design-system/dist/types/components/Button';
import { Badge } from '@sravni/react-design-system/lib/Badge';
import { Button } from '@sravni/react-design-system/lib/Button';
import { Space } from '@sravni/react-design-system/lib/Space';

import { FULL_DEAL_ENTRY_POINT_TEXTS, ONE_LINK } from '@src/constants/fullDeal';
import { sendOpenAppFullDealEvent } from '@src/helpers/analyticsEvents';
import { formatMoney } from '@src/helpers/formatMoney';
import { formatFullDealOfferTerm } from '@src/helpers/fullDeal';

import styles from './styles.module.scss';

const { Heading, Text } = Typography;

type Props = {
  amount: number;
  term: number;
  hasIssuedMicrocreditDeals: boolean;
  toFullDealButtonText: string;
  hasOffer: boolean;
  seoHeading?: string;
  toFullDealButtonColor?: ButtonProps['color'];
  toFullDealButtonRef?: RefObject<HTMLButtonElement>;
  toFullDealButtonOnClick?: (event: SyntheticEvent, buttonLabel: string) => void;
};

export const FullDealOffer = memo(
  ({
    amount,
    term,
    hasIssuedMicrocreditDeals,
    toFullDealButtonText,
    hasOffer,
    seoHeading,
    toFullDealButtonColor = 'gray',
    toFullDealButtonRef,
    toFullDealButtonOnClick,
  }: Props) => {
    const handleOpenMobileApp = (event: React.SyntheticEvent) => {
      event.stopPropagation();

      sendOpenAppFullDealEvent(seoHeading);
      window.open(ONE_LINK, '_blank');
    };

    const { displayedTerm, postfix } = formatFullDealOfferTerm(term);
    const termBadgeLabel = `НА ${displayedTerm} ${postfix}`;

    return (
      <div className={styles.wrapper}>
        <Heading level={3} className="h-text-center">
          {hasOffer ? FULL_DEAL_ENTRY_POINT_TEXTS.hasOfferHeading : FULL_DEAL_ENTRY_POINT_TEXTS.hasFormHeading}
        </Heading>

        <Space size={8} justify="center">
          <Badge variant="secondary" color="gray" text={formatMoney(amount, { symbol: 'РУБ' })} />
          <Badge variant="secondary" color="gray" text={termBadgeLabel} />
        </Space>

        <Text size={14} className="h-color-D60 h-text-center h-mb-8">
          {FULL_DEAL_ENTRY_POINT_TEXTS.hasFormOrOfferDescription}
        </Text>

        <Button
          variant="primary"
          color={toFullDealButtonColor}
          size={52}
          onClick={(event) => toFullDealButtonOnClick(event, toFullDealButtonText)}
          className={styles.buttonFullWidth}
          ref={toFullDealButtonRef}
        >
          {toFullDealButtonText}
        </Button>

        {hasIssuedMicrocreditDeals && (
          <Button size={52} color="gray" onClick={handleOpenMobileApp}>
            {FULL_DEAL_ENTRY_POINT_TEXTS.mobileAppButtonText}
          </Button>
        )}
      </div>
    );
  },
);

FullDealOffer.displayName = 'FullDealOffer';

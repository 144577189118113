const fullDealVitrinUrls = [
  '/zaimy/na-kartu-bez-otkazov/',
  '/zaimy/s-plokhoj-kreditnoj-istoriej/',
  '/zaimy/top/',
  '/zaimy/onlain/',
  '/zaimy/bez-protsentov/',
  '/zaimy/pod-pts/',
  '/zaimy/cherez-gosuslugi/',
  '/zaimy/na-kartu-do-zarplaty/',
  '/zaimy/dolgosrochnye/',
  '/zaimy/s-prosrochkami/',
];

export const FULL_DEAL_VITRIN_URLS_SET = new Set(fullDealVitrinUrls);

import cn from 'classnames';
import { useRouter } from 'next/router';
import React, { memo, useCallback } from 'react';

import { Layout } from '@sravni/react-design-system';
import { useIsMobile } from '@sravni/react-utils';

import type { ISortItems } from '@src/components/ProductList/components/Sort';
import { Sort } from '@src/components/ProductList/components/Sort';
import { FILTERS_NAMES, SORT_SOURCE_AB, SORT_SOURCE_NA_KARTU, SORT_SOURCE_UTM } from '@src/constants/filters';
import type { IFilter } from '@src/hooks/useFilters';
import { useHasUtmLabel } from '@src/reducers/route';
import { isNaKartuPage } from '@src/utils/routing';

import styles from './styles.module.scss';

type Props = {
  filter: IFilter;
  className?: string;
  hasSortLabel: boolean;
};

export const SortPanel = memo(({ filter, className, hasSortLabel }: Props) => {
  const isMobile = useIsMobile();
  const { filters, setFilter } = filter;
  const hasUtmLabel = useHasUtmLabel();
  const { asPath } = useRouter();
  const [pathWithoutQuery] = asPath.split('?');

  const hasNewSortOption = isNaKartuPage(pathWithoutQuery);

  const sort = filters[FILTERS_NAMES.SORT];

  const onSort = useCallback<typeof setFilter>(
    (key, value) => {
      setFilter(key, value);
    },
    [setFilter],
  );

  const getSortOption = (): ISortItems[] => {
    switch (true) {
      case hasUtmLabel:
        return SORT_SOURCE_UTM;

      case hasNewSortOption:
        return SORT_SOURCE_NA_KARTU;

      default:
        return SORT_SOURCE_AB;
    }
  };

  const source = getSortOption();

  if (isMobile) {
    return (
      <Sort
        value={sort}
        onChange={onSort}
        className={cn(styles.sortWrapper, className)}
        source={hasNewSortOption ? SORT_SOURCE_NA_KARTU : SORT_SOURCE_AB}
        hasSortLabel={hasSortLabel}
      />
    );
  }

  return (
    <div className={styles.container}>
      <Layout className={styles.layout}>
        <Sort value={sort} onChange={onSort} source={source} hasSortLabel={hasSortLabel} />
      </Layout>
    </div>
  );
});

import cn from 'classnames';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { Layout } from '@sravni/react-design-system';
import { PreFooter } from '@sravni/react-pre-footer';
import { useDeviceInfoContext, useIsMobile } from '@sravni/react-utils';
import type { Seo } from '@sravni/types/lib/seo';

import { Breadcrumbs } from '@src/components/Breadcrumbs';
import { AnchorLinkList } from '@src/components/PageHeader/AnchorLinkList';
import { usePageContext } from '@src/config';
import { getCreditsLists } from '@src/reducers/microcredits';
import { useHasUtmLabel } from '@src/reducers/route';
import { checkIsFullDealBlockVisible } from '@src/utils/checkIsFullDealBlockVisible';
import { isKZLandingPage, isMainPage, isNaKartuPage, isSpecialPage } from '@src/utils/routing';

import { Disclaimer, DisclaimerBranding } from './Disclaimer';
import { SeoBlocks } from './SeoBlocks';
import styles from './styles.module.scss';

interface IProps {
  className?: string;
  seo: Seo;
  breadcrumbs?: Seo['breadcrumbs'];
  breadcrumbsClassName?: string;
  prefooter?: Seo['prefooter'];
}

const SeoComponents: FC<IProps> = memo(({ className, seo, breadcrumbs = [], breadcrumbsClassName, prefooter = [] }) => {
  const { asPath, query } = useRouter();
  const isMobile = useIsMobile();
  const { phone: isPhone } = useDeviceInfoContext();
  const isRenderDisclaimer = isMainPage(asPath, query) || isSpecialPage(asPath, query);

  const { listKey } = usePageContext();
  const { items } = useSelector(getCreditsLists)[listKey] || { items: [] };
  const hasUtmLabel = useHasUtmLabel();

  if (!isRenderDisclaimer && hasUtmLabel) {
    return null;
  }

  const [pathWithoutQuery] = asPath.split('?');
  const hasSubHeadingAfterOffers =
    seo.subHeading &&
    isMobile &&
    !isNaKartuPage(pathWithoutQuery) &&
    !isKZLandingPage(pathWithoutQuery) &&
    !hasUtmLabel;

  // на витринах с точкой входа в ПС, перелинковку опускаем вниз к SEO блокам
  const isFullDealBlockVisible = isPhone && checkIsFullDealBlockVisible(pathWithoutQuery) && !hasUtmLabel;

  return (
    <div className={cn(styles.container, className)}>
      {hasSubHeadingAfterOffers && <div className={styles.subHeading}>{seo.subHeading}</div>}

      {isFullDealBlockVisible && (
        <div className={styles.linksContainer}>
          <AnchorLinkList />
        </div>
      )}

      {!hasUtmLabel && (
        <Layout className={styles.layout}>
          <SeoBlocks className={styles.table} contentBlocks={seo} items={items} />
        </Layout>
      )}

      {isRenderDisclaimer && (
        <Layout className={cn({ [styles.utm]: hasUtmLabel })}>
          <Disclaimer />
          <DisclaimerBranding />
        </Layout>
      )}

      {!hasUtmLabel && <Breadcrumbs items={breadcrumbs} className={breadcrumbsClassName} />}

      {!hasUtmLabel && <PreFooter columns={prefooter} className={styles.prefooter} />}
    </div>
  );
});

export default SeoComponents;

import { useRouter } from 'next/router';
import React, { useCallback } from 'react';

import { Typography } from '@sravni/react-design-system';
import { Button } from '@sravni/react-design-system/lib/Button';
import { useDeviceInfoContext, useIsMobile } from '@sravni/react-utils';
import type { Seo } from '@sravni/types/lib/seo';

import type * as Microcredits from '@src/@types/microcredits';
import { AnchorLinkList } from '@src/components/PageHeader/AnchorLinkList';
import { DESKTOP_BUTTON_SIZE, FILTER_INPUT_LABEL, MOBILE_BUTTON_SIZE } from '@src/components/PageHeader/consts';
import { AmountControl } from '@src/components/ui/AmountControlAB';
import { usePageContext } from '@src/config/PageContext';
import { Experiments } from '@src/constants/abTest';
import { RUSSIA_AMOUNT_FIELD_SETTINGS } from '@src/constants/amountSettings';
import { PRODUCT_LIST_ANCHOR } from '@src/constants/common';
import { FILTERS_NAMES } from '@src/constants/filters';
import { sendShowProductEvent } from '@src/helpers/analyticsEvents';
import { scrollToElementById } from '@src/helpers/common';
import { useTestVariant } from '@src/hooks/useTestVariants';
import { useSelectCreditsList } from '@src/reducers/microcredits';
import { useHasUtmLabel } from '@src/reducers/route';
import { checkIsFullDealBlockVisible } from '@src/utils/checkIsFullDealBlockVisible';

import { FullDealForm } from './components';
import styles from './styles.module.scss';

interface ISimplePageHeaderFiltersProps {
  className?: string;
  difference?: number;
  filters: Microcredits.IFilters;
  conditionsActive?: boolean;
  onChange: (value: any, name: string) => void;
  onToggleConditionDialog?: () => void;
  seo?: Partial<Seo>;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}
const { Heading } = Typography;

export const SimplePageHeaderFilters: React.FunctionComponent<ISimplePageHeaderFiltersProps> = React.memo(
  ({ filters, onChange, seo }) => {
    const { listKey } = usePageContext();
    const isMobile = useIsMobile();
    const { phone: isPhone } = useDeviceInfoContext();
    const { total: count = 0 } = useSelectCreditsList(listKey);
    const hasUtmLabel = useHasUtmLabel();
    const { isBVariant } = useTestVariant(Experiments.FULL_DEAL_ENTRY_BLOCK);
    const { asPath } = useRouter();

    const [pathWithoutQuery] = asPath.split('?');
    const isFullDealBlockVisible =
      checkIsFullDealBlockVisible(pathWithoutQuery) && isPhone && isBVariant && !hasUtmLabel;

    const handleClickSelectionButton = useCallback(() => {
      sendShowProductEvent();

      scrollToElementById(PRODUCT_LIST_ANCHOR);
    }, []);

    if (isFullDealBlockVisible) {
      return (
        <FullDealForm
          seo={seo}
          filters={filters}
          onChange={onChange}
          handleClickSelectionButton={handleClickSelectionButton}
        />
      );
    }

    return (
      <>
        {isMobile && !hasUtmLabel && seo && (
          <Heading className={styles.heading} level={1}>
            {seo?.heading ?? 'Займы'}
          </Heading>
        )}

        <div className={styles.wrapper}>
          <div className={styles.input}>
            <div className={styles.sumField}>
              <AmountControl
                name={FILTERS_NAMES.AMOUNT}
                className={styles.amount}
                label={FILTER_INPUT_LABEL}
                value={filters[FILTERS_NAMES.AMOUNT]}
                max={RUSSIA_AMOUNT_FIELD_SETTINGS.max}
                onChange={onChange}
                postfix="₽"
              />
            </div>
            <Button
              variant="primary"
              size={isMobile ? MOBILE_BUTTON_SIZE : DESKTOP_BUTTON_SIZE}
              onClick={handleClickSelectionButton}
              className={styles.buttonFullWidth}
            >
              Показать {count}
            </Button>
          </div>
          <AnchorLinkList />
        </div>
      </>
    );
  },
);

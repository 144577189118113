import cn from 'classnames';
import type { FC } from 'react';
import React from 'react';

import { TermControl } from '@src/components/ui/TermControl';
import { DAYS_IN_YEAR } from '@src/constants/dateTime';
import { FILTERS_NAMES } from '@src/constants/filters';
import {
  AMOUNT_MIN_IL_VALUE,
  TERM_LABEL,
  TERM_MAX,
  TERM_MONTH_MAX,
  TERM_MONTH_OPTIONS,
  TERM_MONTH_TEXT,
  TERM_TEXT,
} from '@src/constants/fullDeal';
import { getDaysPluralize, getMonthPluralize } from '@src/helpers/strings';

import { HelperText } from '../../../../HelperText';
import { TERM_BADGE_LIST } from '../../../consts';
import styles from '../../../styles.module.scss';

const DAYS_IN_MONTH = 30;

type Props = {
  amount: number;
  term: number;
  error: string;
  onChange(value: number): void;
};

export const TermSheetContent: FC<Props> = ({ amount, term, error, onChange }) => {
  const showMonths = amount >= AMOUNT_MIN_IL_VALUE;
  const displayedTerm = showMonths ? Math.min(Math.max(Math.ceil(term / DAYS_IN_MONTH)), TERM_MONTH_MAX) : term;

  const termPostfix = showMonths ? getMonthPluralize(displayedTerm) : getDaysPluralize(displayedTerm);

  const handleChangeTerm = (value: number) => {
    if (showMonths) {
      onChange(value === TERM_MONTH_MAX ? DAYS_IN_YEAR : value * DAYS_IN_MONTH);
    } else {
      onChange(value);
    }
  };

  return (
    <>
      <div>
        <TermControl
          name={FILTERS_NAMES.TERM}
          label={TERM_LABEL}
          value={displayedTerm}
          onChange={handleChangeTerm}
          postfix={termPostfix}
          max={showMonths ? TERM_MONTH_MAX : TERM_MAX}
        />
        <HelperText error={error} text={showMonths ? TERM_MONTH_TEXT : TERM_TEXT} />
      </div>
      <div className={styles.tagContainer}>
        {(showMonths ? TERM_MONTH_OPTIONS : TERM_BADGE_LIST).map(({ label, value }) => (
          <div
            key={value}
            onClick={() => onChange(value)}
            className={cn(styles.badge, {
              [styles.inactive]: term !== value,
              [styles.active]: term === value,
            })}
          >
            {label}
          </div>
        ))}
      </div>
    </>
  );
};

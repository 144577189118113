import type { MouseEvent } from 'react';
import React, { useMemo } from 'react';

import type { ILocation } from '@src/@types/locations';

import styles from './styles.module.scss';

interface IRegionSelectProps {
  locations: ILocation[];
  onRegionSelect: (props: { event: MouseEvent<HTMLAnchorElement>; location: ILocation; type: string }) => void;
  type: 'centers' | 'regions';
}

const mergeLocations = (locations: ILocation[]) =>
  locations.reduce((acc, location) => {
    const symbol = location.name[0].toUpperCase();
    if (acc[symbol]) {
      acc[symbol].push(location);
    } else {
      acc[symbol] = [location];
    }
    return acc;
  }, {} as { [key: string]: ILocation[] });

export const RegionSection: React.FC<IRegionSelectProps> = ({ locations, onRegionSelect, type }) => {
  const locationHash: { [key: string]: ILocation[] } = useMemo(() => mergeLocations(locations), [locations]);

  return (
    <div className={styles.container}>
      {Object.keys(locationHash).map((link) => (
        <div className={styles.section_item} key={link}>
          <div className={styles.symbol}>{link}</div>
          <ul className={styles.region_list}>
            {locationHash[link].map((location) => (
              <li key={location.alias}>
                <a
                  className={styles.region_link}
                  href={location.alias}
                  onClick={(event) =>
                    onRegionSelect({
                      event,
                      location,
                      type,
                    })
                  }
                >
                  {location.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

import { differenceInDays, parse, startOfDay } from 'date-fns';

import { AMOUNT_MIN_IL_VALUE, TERM_MIN, TERM_MONTH_MIN_IN_DAYS } from '@src/constants/fullDeal';

/**
 *  Uses for format short term and return term duration in days
 */
export const getInitialTermWithDaysCount = (shortTerm: string, amount: number) => {
  const term = Math.max(differenceInDays(parse(shortTerm, 'dd.MM.yyyy', new Date()), startOfDay(new Date())), TERM_MIN);

  const showMouthsForTerm = amount >= AMOUNT_MIN_IL_VALUE;

  if (showMouthsForTerm && term < TERM_MONTH_MIN_IN_DAYS) {
    return TERM_MONTH_MIN_IN_DAYS;
  }

  return term;
};

import type { MicrocreditsDto } from '@src/@types/microcreditsDto';
import { MicrocreditStatus } from '@src/@types/microcreditsDto';
import { FULL_DEAL_ENTRY_POINT_TEXTS } from '@src/constants/fullDeal';
import { isoDurationToDays } from '@src/helpers/date';
import { getInitialTermWithDaysCount } from '@src/helpers/fullDeal';

export const getDataAboutMicrocreditDeals = (
  microcreditData: MicrocreditsDto,
  initialAmount: number,
  initialTerm: number | string,
  shortTermFormatter: (shortTerm: string, amount: number) => string | number = getInitialTermWithDaysCount,
) => {
  let hasIssuedMicrocreditDeals = false;
  let toFullDealButtonText = '';
  let hasOffer = false;
  let hasForm = false;
  let isOfferWaiting = false;
  let _initialAmount = initialAmount;
  let _initialTerm = initialTerm;

  microcreditData.deals.forEach(({ status, offer, finalOffer, buttonText }) => {
    if (status === MicrocreditStatus.Issued) {
      hasIssuedMicrocreditDeals = true;
    }

    if (status === MicrocreditStatus.Created && buttonText) {
      toFullDealButtonText = buttonText;

      if (offer) {
        if (finalOffer) {
          _initialAmount = finalOffer.amount;
          _initialTerm = isoDurationToDays(finalOffer.term);
        } else {
          _initialAmount = offer.amount;
          _initialTerm = isoDurationToDays(offer.term);
        }

        hasOffer = true;
      } else {
        isOfferWaiting = true;
      }
    }
  });

  if (!hasOffer && microcreditData.form) {
    if (microcreditData.form.buttonText && !isOfferWaiting) {
      toFullDealButtonText = microcreditData.form.buttonText;
    }

    if (!isOfferWaiting) {
      hasForm = true;
    }

    if (microcreditData.form.amount) {
      _initialAmount = microcreditData.form.amount;
    }

    if (microcreditData.form.term) {
      _initialTerm = isoDurationToDays(microcreditData.form.term);
    } else if (microcreditData.form.shortTerm) {
      _initialTerm = shortTermFormatter(microcreditData.form.shortTerm, _initialAmount);
    }
  }

  return {
    hasIssuedMicrocreditDeals,
    toFullDealButtonText:
      toFullDealButtonText ||
      (hasIssuedMicrocreditDeals
        ? FULL_DEAL_ENTRY_POINT_TEXTS.newDealButtonText
        : FULL_DEAL_ENTRY_POINT_TEXTS.completeDeal),
    hasOffer,
    hasForm,
    isOfferWaiting,
    initialAmount: _initialAmount,
    initialTerm: _initialTerm,
  };
};

import getConfig from 'next/config';

import type * as Microcredits from '@src/@types/microcredits';
import type { IPixels } from '@src/utils/promo';
import { updateHasOffersLinks } from '@src/utils/promo';

import client from '../utils/client';

enum LEADS_SU_LIST { // продукты выводятся в порядке, указанном тут
  MoneyMan = '5e5fc1377a66e4001b2ba980',
  PayPs = '5e9c20f69ad83a001b4ae932',
  VivaDengi = '5e9ecd5bd50b49001bf7992e',
  Moneza = '5e95db3f6a6640001b655d46',
  DoZarplaty = '5e95d2be3261b1001b9b5358',
  Zaymigo = '5e95ee936e1d09001b5f828c',
  WebZaym = '5e9d4873cc9ae4001b5d7d57',
  EKapusta = '5e95c24e15a65c001bab5035',
  Vivus = '5e9f22fbecf88e001bcb7b81',
  DengiSrazu = '5ea1b0e26c1f2a001b38c21d',
  Zaymer = '5e95e240b92fe7001be7598c',
  Kredito24 = '5e988f68363fc3001b76e173',
  Webbankir = '5e95c9aa31b105001bb766b8',
  BystroDengi = '60250eeeb5d14e001f28ba1e',
}

const hasOffersLinks = new Map<LEADS_SU_LIST, IPixels>([
  [
    LEADS_SU_LIST.MoneyMan,
    {
      click: 'https://sravni.go2cloud.org/aff_c?offer_id=1279&aff_id=2&url_id=2465',
      display: 'https://sravni.go2cloud.org/aff_i?offer_id=1279&aff_id=2&url_id=2465',
    },
  ],
  [
    LEADS_SU_LIST.PayPs,
    {
      click: 'https://sravni.go2cloud.org/aff_c?offer_id=1279&aff_id=2&url_id=2466',
      display: 'https://sravni.go2cloud.org/aff_i?offer_id=1279&aff_id=2&url_id=2466',
    },
  ],
  [
    LEADS_SU_LIST.VivaDengi,
    {
      click: 'https://sravni.go2cloud.org/aff_c?offer_id=1279&aff_id=2&url_id=2467',
      display: 'https://sravni.go2cloud.org/aff_i?offer_id=1279&aff_id=2&url_id=2467',
    },
  ],
  [
    LEADS_SU_LIST.Moneza,
    {
      click: 'https://sravni.go2cloud.org/aff_c?offer_id=1279&aff_id=2&url_id=2468',
      display: 'https://sravni.go2cloud.org/aff_i?offer_id=1279&aff_id=2&url_id=2468',
    },
  ],
  [
    LEADS_SU_LIST.DoZarplaty,
    {
      click: 'https://sravni.go2cloud.org/aff_c?offer_id=1279&aff_id=2&url_id=2469',
      display: 'https://sravni.go2cloud.org/aff_i?offer_id=1279&aff_id=2&url_id=2469',
    },
  ],
  [
    LEADS_SU_LIST.Zaymigo,
    {
      click: 'https://sravni.go2cloud.org/aff_c?offer_id=1279&aff_id=2&url_id=2470',
      display: 'https://sravni.go2cloud.org/aff_i?offer_id=1279&aff_id=2&url_id=2470',
    },
  ],
  [
    LEADS_SU_LIST.WebZaym,
    {
      click: 'https://sravni.go2cloud.org/aff_c?offer_id=1279&aff_id=2&url_id=2471',
      display: 'https://sravni.go2cloud.org/aff_i?offer_id=1279&aff_id=2&url_id=2471',
    },
  ],
  [
    LEADS_SU_LIST.EKapusta,
    {
      click: 'https://sravni.go2cloud.org/aff_c?offer_id=1235&aff_id=2&url_id=2445',
      display: 'https://sravni.go2cloud.org/aff_i?offer_id=1235&aff_id=2&url_id=2445',
    },
  ],
  [
    LEADS_SU_LIST.Vivus,
    {
      click: 'https://sravni.go2cloud.org/aff_c?offer_id=1238&aff_id=2&url_id=2451',
      display: 'https://sravni.go2cloud.org/aff_i?offer_id=1238&aff_id=2&url_id=2451',
    },
  ],
  [
    LEADS_SU_LIST.DengiSrazu,
    {
      click: 'https://sravni.go2cloud.org/aff_c?offer_id=1227&aff_id=2&url_id=2449',
      display: 'https://sravni.go2cloud.org/aff_i?offer_id=1227&aff_id=2&url_id=2449',
    },
  ],
  [
    LEADS_SU_LIST.Zaymer,
    {
      click: 'https://sravni.go2cloud.org/aff_c?offer_id=1234&aff_id=2&url_id=2452',
      display: 'https://sravni.go2cloud.org/aff_i?offer_id=1234&aff_id=2&url_id=2452',
    },
  ],
  [
    LEADS_SU_LIST.Kredito24,
    {
      click: 'https://sravni.go2cloud.org/aff_c?offer_id=249&aff_id=2&url_id=2447',
      display: 'https://sravni.go2cloud.org/aff_i?offer_id=249&aff_id=2&url_id=2447',
    },
  ],
  [
    LEADS_SU_LIST.Webbankir,
    {
      click: 'https://sravni.go2cloud.org/aff_c?offer_id=1237&aff_id=2&url_id=2453',
      display: 'https://sravni.go2cloud.org/aff_i?offer_id=1237&aff_id=2&url_id=2453',
    },
  ],
  [
    LEADS_SU_LIST.BystroDengi,
    {
      click: 'https://sravni.go2cloud.org/aff_c?offer_id=811&aff_id=2&url_id=2454',
      display: 'https://sravni.go2cloud.org/aff_i?offer_id=811&aff_id=2&url_id=2454',
    },
  ],
]);

const ids = Object.values(LEADS_SU_LIST);
const size = ids.length * 2; // промо формируется по-принципу список продуктов * 2
const order = new Map(ids.map((productId, index) => [productId, index + 1]));

const getProductSortIndex = (microCredit: Microcredits.List.IMicrocredit) =>
  order.get(microCredit._id as LEADS_SU_LIST) || size + 1;

const compareProducts = (microCredit1: Microcredits.List.IMicrocredit, microCredit2: Microcredits.List.IMicrocredit) =>
  getProductSortIndex(microCredit1) - getProductSortIndex(microCredit2);

export const loadLeadsSuList = async (params: Microcredits.List.IReq) => {
  const { data } = await client.get<Microcredits.List.IRes>(
    `${getConfig().publicRuntimeConfig.publicUrl}/v1/microCredit/list/`,
    {
      params: {
        ids,
        currency: params.currency,
        limit: params.limit,
        location: params.location,
        skip: params.skip,
      },
      timeout: 1500,
    },
  );

  const updateItems = updateHasOffersLinks(hasOffersLinks);
  // @ts-ignore
  const list = data.items.map(updateItems).sort(compareProducts);
  const promoItems = [...list, ...list];
  const availableProducts = size > promoItems.length ? promoItems.length : size;

  return { ...data, count: availableProducts, total: availableProducts, items: promoItems };
};

import getConfig from 'next/config';
import type { FC } from 'react';
import React, { createContext } from 'react';

interface IConfig {
  gatewayUrl?: string;
  publicUrl?: string;
  environment?: string;
}

const runtimeConfig: IConfig = {
  gatewayUrl: getConfig().publicRuntimeConfig.gatewayUrl,
  publicUrl: getConfig().publicRuntimeConfig.publicUrl,
  environment: getConfig().publicRuntimeConfig.environment,
};

const ConfigContext = createContext<IConfig>({
  environment: 'development',
  gatewayUrl: 'https://gateway.svc.platform.dev.lan',
  publicUrl: 'https://public.sravni.ru.dev.lan',
});

export const ConfigProvider: FC = ({ children }) => (
  <ConfigContext.Provider value={runtimeConfig}>{children}</ConfigContext.Provider>
);

import { useRouter } from 'next/router';
import React, { useCallback, useRef } from 'react';

import { Typography } from '@sravni/react-design-system';
import { Badge } from '@sravni/react-design-system/lib/Badge';
import { Button } from '@sravni/react-design-system/lib/Button';
import { Icon } from '@sravni/react-design-system/lib/Icon';
import { Space } from '@sravni/react-design-system/lib/Space';
import { Plus } from '@sravni/react-icons';

import { SessionStorageKeys } from '@src/@types/sessionStorage';
import { FullDeal } from '@src/assets';
import { FullDealOffer } from '@src/components/FullDealOffer';
import { OptimizedPicture } from '@src/components/ui/OptimizedPicture';
import { FULL_DEAL_ENTRY_POINT_TEXTS, ONE_LINK, WEB_FULL_DEAL_LINK } from '@src/constants/fullDeal';
import { StickyBlock } from '@src/containers/list/ProductList/FullDealEntryPoint/components/StickyBlock';
import { sendOpenAppFullDealEvent, sendOpenWebFullDealEvent } from '@src/helpers/analyticsEvents';
import { useMicrocreditDeals } from '@src/hooks/useMicrocreditDeals';
import { useVisibility } from '@src/hooks/useVisibility';

import styles from './styles.module.scss';

const { Heading, Text } = Typography;

export const FullDealEntryPoint = () => {
  const { asPath } = useRouter();

  const {
    hasIssuedMicrocreditDeals,
    hasOffer,
    hasForm,
    isOfferWaiting,
    toFullDealButtonText,
    initialAmount,
    initialTerm,
  } = useMicrocreditDeals({});

  const toFullDealButtonRef = useRef<HTMLButtonElement>(null);
  const isFullDealEntryVisible = useVisibility(toFullDealButtonRef);

  const handleOpenWebFullDeal = useCallback(
    (event: React.SyntheticEvent, buttonLabel: string) => {
      event.stopPropagation();

      sessionStorage.setItem(SessionStorageKeys.referer, asPath);
      sendOpenWebFullDealEvent(buttonLabel);
      window.location.href = WEB_FULL_DEAL_LINK;
    },
    [asPath],
  );

  const handleOpenMobileApp = (event: React.SyntheticEvent) => {
    event.stopPropagation();

    sendOpenAppFullDealEvent(FULL_DEAL_ENTRY_POINT_TEXTS.mobileAppButtonText);
    window.open(ONE_LINK, '_blank');
  };

  const hasOfferOrForm = hasForm || hasOffer || isOfferWaiting;
  const localToFullDealButtonText =
    !hasOfferOrForm && !hasIssuedMicrocreditDeals
      ? FULL_DEAL_ENTRY_POINT_TEXTS.redirectButtonText
      : toFullDealButtonText;

  return (
    <>
      {hasOfferOrForm && (
        <div className={styles.container}>
          <FullDealOffer
            amount={initialAmount}
            term={initialTerm as number}
            hasOffer={hasOffer}
            toFullDealButtonText={toFullDealButtonText}
            hasIssuedMicrocreditDeals={hasIssuedMicrocreditDeals}
            toFullDealButtonColor="blue"
            toFullDealButtonRef={toFullDealButtonRef}
            toFullDealButtonOnClick={handleOpenWebFullDeal}
          />
        </div>
      )}

      {!hasOfferOrForm && (
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <OptimizedPicture width={176} height={176} img={FullDeal} alt="fullDeal" />

            <div className={styles.content}>
              <Badge variant="primary" color="green" text="Первый займ под 0%" />
              <Heading level={3}>{FULL_DEAL_ENTRY_POINT_TEXTS.heading}</Heading>

              <Text size={14} className={styles.description}>
                {FULL_DEAL_ENTRY_POINT_TEXTS.description}
              </Text>
            </div>

            <Space size={8} direction="vertical">
              <Button
                size={52}
                block
                variant="primary"
                color="blue"
                onClick={(event) => handleOpenWebFullDeal(event, localToFullDealButtonText)}
                ref={toFullDealButtonRef}
              >
                {hasIssuedMicrocreditDeals && <Icon size={20} strong icon={<Plus />} />}
                {localToFullDealButtonText}
              </Button>

              {hasIssuedMicrocreditDeals && (
                <Button size={52} block color="gray" onClick={handleOpenMobileApp}>
                  {FULL_DEAL_ENTRY_POINT_TEXTS.mobileAppButtonText}
                </Button>
              )}
            </Space>
          </div>
        </div>
      )}

      {!isFullDealEntryVisible && (
        <StickyBlock
          amount={initialAmount}
          hasOffer={hasOffer}
          hasForm={hasForm}
          isOfferWaiting={isOfferWaiting}
          hasIssuedMicrocreditDeals={hasIssuedMicrocreditDeals}
          buttonLabel={localToFullDealButtonText}
          onButtonClick={(event) => handleOpenWebFullDeal(event, localToFullDealButtonText)}
        />
      )}
    </>
  );
};

import type { FC, ReactNode } from 'react';
import React from 'react';

import { MobileAppBanner } from '@src/components/MobileAppBanner';
import { NoScript } from '@src/components/NoScript';
import SeoHead from '@src/components/Seo/SeoHead';
import { useHasUtmLabel } from '@src/reducers/route';
import { TopBanner } from 'entities/Banner';
import { Footer } from 'widgets/Footer';
import { Header } from 'widgets/Header';

import { useFetchRegions, useInitSentryAndMindbox, useSetHistory } from '../lib';

import { CustomCommonHead } from './CustomCommonHead/CustomCommonHead';
import styles from './Layout.module.scss';

interface Props {
  mode?: 'landing' | 'default';
  footer?: ReactNode;
  header?: ReactNode;

  showMobileAppBanner?: boolean;
  isWhiteLabel?: boolean;

  containerClassName?: string;
}

export const Layout: FC<Props> = ({
  children,
  mode = 'default',
  footer = <Footer mode={mode} />,
  header = <Header mode={mode} />,
  showMobileAppBanner = true,
  isWhiteLabel = false,
  containerClassName,
}) => {
  const isContextPage = useHasUtmLabel();

  // todo(MFO-1275): Распределить хуки по фичам
  useFetchRegions();
  useInitSentryAndMindbox();
  useSetHistory();

  const _showMobileAppBanner = !isContextPage && showMobileAppBanner;

  return (
    <>
      <CustomCommonHead isWhiteLabel={isWhiteLabel} />
      <NoScript isWhiteLabel={isWhiteLabel} />
      <SeoHead />

      <div className={containerClassName}>
        <div className={styles.container}>
          <TopBanner />

          {_showMobileAppBanner && <MobileAppBanner />}

          {header}

          {children}
        </div>

        {footer}
      </div>
    </>
  );
};

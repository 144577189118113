import type { FC } from 'react';

import { Typography } from '@sravni/react-design-system';
import type { Seo } from '@sravni/types/lib/seo';

import type { IFilters } from '@src/@types/microcredits';
import { MobilePageHeading } from '@src/components/details/components/PageHeaderFilters/components/MobilePageHeading';
import { AMOUNT_DEFAULT, TERM_DEFAULT } from '@src/constants/fullDeal';
import { FullDealEntryPoint } from '@src/containers/list/ProductList/FullDealEntryPoint';
import { sendAmountFilterEvent, sendTermFilterEvent } from '@src/helpers/analyticsEvents';

interface Props {
  filters: IFilters;
  seo?: Partial<Seo>;
  onChange: (value: any, name: string) => void;
}

export const FullDealEntrypoint: FC<Props> = ({ filters, seo, onChange }) => (
  <>
    <Typography.Heading className="h-p-16 h-text-center" level={1}>
      {seo?.heading ?? 'Займы'}
    </Typography.Heading>

    <FullDealEntryPoint />

    <MobilePageHeading
      amountChangeAnalyticEvent={() => sendAmountFilterEvent(true)}
      termChangeAnalyticEvent={() => sendTermFilterEvent(true)}
      initialAmount={filters.amount ?? AMOUNT_DEFAULT}
      onChange={onChange}
      initialTerm={filters.term ?? TERM_DEFAULT}
    />
  </>
);

import Head from 'next/head';
import type { FC } from 'react';
import { useSelector } from 'react-redux';

import { LISTS_KEYS } from '@src/components/Seo/consts';
import { checkAndSendPageViewGAEvent } from '@src/helpers/analyticsEvents';
import { useCurrentLocation } from '@src/reducers/locations';
import { getCreditsLists, getCreditsMetadata } from '@src/reducers/microcredits';
import { selectItemSource } from '@src/reducers/product';
import { useSeoState } from '@src/reducers/seo';
import { isServer } from '@src/utils';
import { getCommonReplacements } from '@src/utils/meta';

import { generateJsonLdForItemPage, getDescription, getTitle } from './ItemPageJsonLd';
import { generateJsonLdForListPage } from './ListPageJsonLd';

const SeoHead: FC = () => {
  const { pathname, seo } = useSeoState();
  const metadata = useSelector(getCreditsMetadata);
  const currentLocation = useCurrentLocation();
  const lists = useSelector(getCreditsLists);
  const cardSource = useSelector(selectItemSource);

  if (pathname?.includes('mfo') && cardSource) {
    const jsonLd = generateJsonLdForItemPage(cardSource);

    return (
      <Head>
        <title>{getTitle(cardSource)}</title>
        <meta name="description" content={getDescription(cardSource)} />
        <link rel="canonical" href={`https://www.sravni.ru${pathname}`} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://f.sravni.ru/f/_/logo_200x200.jpg" />
        <meta property="og:image:width" content="200" />
        <meta property="og:image:height" content="200" />
        {jsonLd && <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: jsonLd }} />}
      </Head>
    );
  }

  if (!seo?.title || !seo?.description) {
    return null;
  }

  if (!isServer) {
    checkAndSendPageViewGAEvent(seo.title, pathname);
  }

  const getJsonLd = () => {
    const currentListsKey = LISTS_KEYS.find((key) => lists[key]?.items?.length);

    if (currentListsKey) {
      const microcredits = lists[currentListsKey];
      return generateJsonLdForListPage({ seo, microcredits, currentLocation, metadata });
    }

    return JSON.stringify(seo.schema);
  };

  const jsonLd = getJsonLd();

  return (
    <Head>
      <title>{getCommonReplacements({ template: seo.title, meta: metadata, location: currentLocation })}</title>
      <meta name="robots" content={seo.indexingDisabled ? 'noindex, nofollow' : 'index, follow'} />
      <meta
        name="description"
        content={getCommonReplacements({
          template: seo.description,
          meta: metadata,
          location: currentLocation,
        })}
      />
      <link rel="canonical" href={seo.canonical || `https://www.sravni.ru${pathname}`} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={seo.ogDescription} />
      <meta property="og:title" content={seo.ogTitle} />
      <meta property="og:image" content="https://f.sravni.ru/f/_/logo_200x200.jpg" />
      <meta property="og:image:width" content="200" />
      <meta property="og:image:height" content="200" />
      {jsonLd && <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: jsonLd }} />}
    </Head>
  );
};

export default SeoHead;

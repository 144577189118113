import throttle from 'lodash/throttle';
import { useEffect, useState } from 'react';

const DEFAULT_DELAY = 250;

type WindowSize = {
  width: number;
  height: number;
};

type Props = Partial<WindowSize> & {
  delay?: number;
};

export const useWindowSize = ({
  width = window.innerWidth,
  height = window.innerHeight,
  delay = DEFAULT_DELAY,
}: Props) => {
  const [state, setState] = useState<WindowSize>({ width, height });

  useEffect(() => {
    const handler = throttle(() => {
      setState({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, delay);

    window.addEventListener('resize', handler);
    return () => {
      window.removeEventListener('resize', handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return state;
};

import { useIsMobile } from '@sravni/react-utils';

import { DEFAULT_FILTERS, MODES } from '@src/constants/filters';
import type { ListKeys } from '@src/constants/microcredits';
import { useFilter } from '@src/hooks/useFilters';
import { useSeoState } from '@src/reducers/seo';

export const useLandingPage = (key: ListKeys) => {
  const { filters: seoFilters } = useSeoState();
  const isMobile = useIsMobile();

  const filter = useFilter({
    defaultValue: {
      ...DEFAULT_FILTERS[MODES.DEFAULT],
      ...seoFilters,
      ...(isMobile && { limit: DEFAULT_FILTERS[MODES.DEFAULT].limitMobile }),
    },
    name: key,
  });

  return {
    filter,
  };
};

import type { IClientMicrocredit, IClientMicrocreditListItem } from '@src/@types/clienttypes';
import type { AffSub4 } from '@src/@types/microcredits';
import { AccordionDetails } from '@src/components/details/components/AccordionDetails';
import { DetailsCard } from '@src/components/details/components/DetailsCard';
import { OrganizationRatingBlock } from '@src/components/details/components/OrganizationRatingBlock';
import { RedirectReviewButton } from '@src/components/details/components/RedirectReviewButton';
import { LocalDialog } from '@src/components/LocalDialog';
import { NA_KARTU_PAGE_DEFAULT_AMOUNT, NA_KARTU_PAGE_DEFAULT_TERM } from '@src/constants/filters';
import type { IAnalyticsItem } from '@src/helpers/analyticsEvents';

import { useGetProduct } from './hooks';
import styles from './styles.module.scss';

interface Props {
  isOpen: boolean;
  offer: IClientMicrocreditListItem;
  onClose: () => void;
}

export const CardDetailsModal = ({ isOpen, offer, onClose }: Props) => {
  const card = useGetProduct({ visible: isOpen, offer }) as IClientMicrocredit;

  const { organization, advertising } = card;
  const reviewLink = `/zaimy/mfo/${organization?.alias}/otzyvy/add/`;

  const affSub4: AffSub4 = {
    organizationName: organization?.name,
    productName: card.name,
    organizationId: organization?._id,
    productId: card._id,
  };

  const analytics: IAnalyticsItem = {
    eventAction: advertising.buttonEventAction,
    advertising,
    _id: card._id,
    name: card.name,
    organization: { name: organization.name, _id: organization._id },
  };

  return (
    <LocalDialog visible={isOpen} onClose={onClose} bodyClassName={styles.body}>
      <div className={styles.container}>
        <div className={styles.scrolledBlock}>
          <div>
            <DetailsCard
              analytics={analytics}
              amount={NA_KARTU_PAGE_DEFAULT_AMOUNT}
              term={NA_KARTU_PAGE_DEFAULT_TERM}
              affSub4={affSub4}
              card={card}
            />
          </div>

          <AccordionDetails
            card={card}
            fieldsState={{
              prolongation: false,
              penalty: false,
              repaymentsConditions: false,
              support: false,
              documents: false,
            }}
          />

          <OrganizationRatingBlock card={card} />
        </div>

        <RedirectReviewButton
          link={reviewLink}
          text="Оставить свой отзыв о займе"
          organizationName={organization.name}
          advertising={card.advertising}
          name={card.name}
          className={styles.reviewButtonWrapper}
        />
      </div>
    </LocalDialog>
  );
};

import { memo } from 'react';

import { FILTERS_NAMES } from '@src/constants/filters';

import { AmountSheetContent, TermSheetContent } from './components';

type Props = {
  activeField: string;
  amount: number;
  term: number;
  onChange: (value: number) => void;
  error?: string;
};

export const SheetContent = memo(({ activeField, amount, term, onChange, error }: Props) => {
  const isAmountField = activeField === FILTERS_NAMES.AMOUNT;

  if (isAmountField) {
    return <AmountSheetContent amount={amount} onChange={onChange} error={error} />;
  }

  return <TermSheetContent amount={amount} term={term} onChange={onChange} error={error} />;
});

import type { IAdvertising } from '@src/@types/advertising';
import { MONETIZATION_TYPES } from '@src/constants/advertising';
import { FILTERS_DICTIONARIES_MAP, FILTERS_LABELS, FILTERS_NAMES, SORT_LABELS } from '@src/constants/filters';
import { itemLabelFactory, makeAdGEvent, makeMainGEvent, pushEvent } from '@src/utils/analytics';

import { DEFAULT_EVENT_VALUE, EVENT_CATEGORY, EVENT_CATEGORY_FULL_DEAL, PAGE_VIEW_GA_STATE } from './constants';

export interface IAnalyticsItem {
  eventAction?: string;
  advertising?: IAdvertising;
  _id?: string;
  name: string;
  organization: {
    name: string;
    _id?: string;
  };
}

const getEventValueFromItem = (item: IAnalyticsItem) => {
  if (item.advertising) {
    return item.advertising.cost;
  }

  return undefined;
};

/** AD G_EVENTS * */
export const sendExtraFiltersOpenEvent = () => {
  pushEvent(
    makeAdGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: 'Еще условия',
    }),
  );
};

const sendSortEvent = (value: string) => {
  pushEvent(
    makeAdGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: 'Сортировка',
      eventLabel: value,
    }),
  );
};

const sendFilterEvent = (label: string, values: string | string[]) => {
  pushEvent(
    makeAdGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: ['Фильтр', label],
      eventLabel: values,
    }),
  );
};

export const sendPaginationEvent = () => {
  pushEvent(
    makeAdGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: 'Показать еще',
    }),
  );
};

export const sendChangeAmountFilterEvent = (amount: number, from: string, offerId?: string) => {
  pushEvent(
    makeAdGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: `Фильтр|Сумма займа|${from}`,
      eventLabel: `${amount}|${offerId}`,
    }),
  );
};

export const sendChangeTermFilterEvent = (term: number, from: string, offerId?: string) => {
  pushEvent(
    makeAdGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: `Фильтр|На какой срок|${from}`,
      eventLabel: `${term}|${offerId}`,
    }),
  );
};

/** MAIN G_EVENTS * */

// @ts-ignore
export const sendSponsorClickEvent = (organizationName) => {
  pushEvent(
    makeMainGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: 'Спонсор раздела',
      eventLabel: organizationName,
    }),
  );
};

const sendPaidClickEvent = (item: IAnalyticsItem) => {
  pushEvent(
    makeMainGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: item.eventAction || item.advertising?.buttonEventAction || 'Платный клик',
      eventLabel: itemLabelFactory(item),
      eventValue: getEventValueFromItem(item),
    }),
  );
};

const sendGoToRequestEvent = (item: IAnalyticsItem) => {
  pushEvent(
    makeMainGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: 'Переход к оформлению заявки',
      eventLabel: itemLabelFactory(item),
    }),
  );
};

export const sendClickOnExtraInformationEvent = (item: IAnalyticsItem) => {
  pushEvent(
    makeMainGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: 'Переход в карточку',
      eventLabel: itemLabelFactory(item),
    }),
  );
};

export const sendOrganizationOpenEvent = (item: IAnalyticsItem) => {
  pushEvent(
    makeMainGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: 'Переход в карточку МФО',
      eventLabel: itemLabelFactory(item),
    }),
  );
};

export const sendClickGetMoneyButtonEvent = (url: string) => {
  pushEvent(
    makeMainGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: 'Кнопка|Получить деньги',
      eventLabel: url,
    }),
  );
};

const REQUESTS_MONETIZATIONS = new Set([
  MONETIZATION_TYPES.REQUEST_LONG,
  MONETIZATION_TYPES.REQUEST_SHORT,
  MONETIZATION_TYPES.APPROVAL,
]);

export const sendAdvertisingButtonClickEvent = (item: IAnalyticsItem) => {
  const { advertising } = item;

  if (advertising) {
    const {
      monetization: { kind },
    } = advertising;

    // todo: maybe this logic is not valid, talk with manager
    if (REQUESTS_MONETIZATIONS.has(kind)) {
      sendGoToRequestEvent(item);
    } else {
      sendPaidClickEvent(item);
    }
  }
};

// @ts-ignore
export const checkAndSendPageViewGAEvent = (title, path) => {
  if (title !== PAGE_VIEW_GA_STATE.title && path !== PAGE_VIEW_GA_STATE.path) {
    if (PAGE_VIEW_GA_STATE.title && PAGE_VIEW_GA_STATE.path) {
      pushEvent({
        event: 'pageViewGA',
        title,
        path,
      });
    }

    Object.assign(PAGE_VIEW_GA_STATE, { title, path });
  }
};

// @ts-ignore
const humanizeFilterValue = (key, value, dictionaries) => {
  // eslint-disable-next-line @typescript-eslint/init-declarations
  let fields;

  switch (key) {
    case FILTERS_NAMES.TERM: {
      // eslint-disable-next-line no-param-reassign
      value = typeof value === 'string' ? Number(value.replace(/[^0-9]/g, '')) : value;
      break;
    }

    default: {
      // @ts-ignore
      ({ fields } = dictionaries?.[FILTERS_DICTIONARIES_MAP[key]] || {});
    }
  }

  if (fields) {
    // `label` is polyfill for some custom fields (for example BANKS_RATINGS)
    // @ts-ignore
    const { name, label } = fields.find((item) => item.value === value) || { name: value };

    return name || label;
  }

  return value;
};

export const sendSidebarClickEvent = (eventLabel: string, eventValue: number | undefined) => {
  pushEvent(
    makeMainGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: 'Платный клик|Сайдбар',
      eventLabel,
      eventValue,
    }),
  );
};

export const sendAdditionalConditionsClickEvent = (eventLabel: string, eventActionValue: string) => {
  pushEvent(
    makeMainGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: `Доп.услуги|pop-up|${eventActionValue}`,
      eventLabel,
    }),
  );
};

export const sendRepaymentConditionsClickEvent = (eventLabel: string, eventActionValue: string) => {
  pushEvent(
    makeMainGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: `Платное погашение|${eventActionValue}`,
      eventLabel,
    }),
  );
};

export const sendOpenAccordionItemEvent = (eventLabel: string, eventActionValue: string) => {
  pushEvent(
    makeMainGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: `Карточка детальной информации|Раскрытие|${eventActionValue}`,
      eventLabel,
    }),
  );
};

export const sendOpenReviewsEvent = (eventLabel: string, eventActionType: string) => {
  pushEvent(
    makeMainGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: `Переход к отзывам|${eventActionType}`,
      eventLabel,
    }),
  );
};

export const sendOpenDetailsCardEvent = (eventLabel: string) => {
  pushEvent(
    makeMainGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: 'Переход в карточку детальной информации',
      eventLabel,
    }),
  );
};

export const sendRepaymentConditionsItemClickEvent = (eventLabel: string, eventActionValue: string) => {
  pushEvent(
    makeMainGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: `Карточка детальной информации|Способы погашения|${eventActionValue}`,
      eventLabel,
    }),
  );
};

export const sendSupportItemClickEvent = (eventLabel: string, eventActionValue: string) => {
  pushEvent(
    makeMainGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: `Карточка детальной информации|Поддержка|${eventActionValue}`,
      eventLabel,
    }),
  );
};

export const sendGeneralInfoItemClickEvent = (eventLabel: string, eventActionValue: string) => {
  pushEvent(
    makeMainGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: `Карточка детальной информации|Общая информация|${eventActionValue}`,
      eventLabel,
    }),
  );
};

export const sendDocumentsItemClickEvent = (eventLabel: string, eventActionValue: string) => {
  pushEvent(
    makeMainGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: `Карточка детальной информации|Документы компании|${eventActionValue}`,
      eventLabel,
    }),
  );
};

export const sendBackLinkClickEvent = (eventLabel: string) => {
  pushEvent(
    makeMainGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: 'Карточка детальной информации|К списку займов',
      eventLabel,
    }),
  );
};

export const sendSortOnDropdownClickEvent = () => {
  pushEvent(
    makeAdGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: 'Сортировка|Сортировать',
      eventLabel: null,
    }),
  );
};

export const sendSortOnDropdownOptionsClickEvent = (value: string) => {
  pushEvent(
    makeAdGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: 'Сортировка|Сортировать',
      eventLabel: value,
    }),
  );
};

export const sendPromotionClickEvent = (organizationName: string) => {
  pushEvent(
    makeMainGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: 'Платный клик|Акция',
      eventLabel: `${organizationName}|Акция`,
      eventValue: DEFAULT_EVENT_VALUE,
    }),
  );
};

export const sendPhoneNumberEvent = () => {
  pushEvent(
    makeMainGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: 'Ввод телефона|Показать вероятность одобрения',
      eventLabel: undefined,
    }),
  );
};

export const sendOpenAppEvent = () => {
  pushEvent(
    makeMainGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: 'Скачать приложение',
      eventLabel: undefined,
    }),
  );
};

export const sendOpenWebFullDealEvent = (buttonLabel: string) => {
  pushEvent(
    makeMainGEvent({
      eventCategory: EVENT_CATEGORY_FULL_DEAL,
      eventAction: 'mfoNextButton',
      eventLabel: `state - Реферальня витрина, Баннер, ${buttonLabel}|screen - mfoReferral`,
      eventValue: undefined,
    }),
  );
};

export const sendOpenAppFullDealEvent = (buttonLabel: string) => {
  pushEvent(
    makeMainGEvent({
      eventCategory: EVENT_CATEGORY_FULL_DEAL,
      eventAction: 'mfoNextButton',
      eventLabel: `state - Реферальня витрина, Баннер, Мои займы (${buttonLabel})|screen - mfoReferral`,
      eventValue: undefined,
    }),
  );
};

export const sendAmountFilterEvent = (eventLabelPostfix = false) => {
  const eventLabel = eventLabelPostfix ? `Фильтр|Сумма займа (bottomsheet)` : 'Фильтр|Сумма займа';

  pushEvent(
    makeMainGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: 'Баннер',
      eventLabel,
      eventValue: undefined,
    }),
  );
};

export const sendTermFilterEvent = (eventLabelPostfix = false) => {
  const eventLabel = eventLabelPostfix ? `Фильтр|Срок (bottomsheet)` : 'Фильтр|Срок';

  pushEvent(
    makeMainGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: 'Баннер',
      eventLabel,
      eventValue: undefined,
    }),
  );
};

export const sendShowProductEvent = () => {
  pushEvent(
    makeMainGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: 'Баннер',
      eventLabel: 'Посмотреть предложения',
    }),
  );
};

// Please create new file for new events

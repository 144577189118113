import isNil from 'lodash/isNil';

import pluralize from '@sravni/utils/lib/pluralize';

import type { IClientMicrocredit } from '@src/@types/clienttypes';
import { Currency } from '@src/@types/currency';
import type { ITermRange } from '@src/@types/ranges';
import { getAmountString, humanizeTerm } from '@src/helpers/common';

export const getAmount = (amountMin: number, amountMax: number) => {
  const minAmount = amountMin ? `от ${getAmountString(amountMin, Currency.RUB)}` : '';
  const maxAmount = amountMax ? `до ${getAmountString(amountMax, Currency.RUB)}` : '';

  if (amountMin && amountMax) {
    return `от ${getAmountString(amountMin)} до ${getAmountString(amountMax, Currency.RUB)}`;
  }

  return maxAmount || minAmount || '';
};

export const getTerm = (term: ITermRange) => {
  const fromValue = term.from ? humanizeTerm(term.from, term.fromUnit, { termWithUnit: false }) : '';
  const toValue = term.to ? humanizeTerm(term.to, term.toUnit, { termWithUnit: false }) : '';
  const pluralizedFromValue = pluralize(Number(fromValue), 'дня', 'дня', 'дней');
  const pluralizedToValue = pluralize(Number(toValue), 'дня', 'дня', 'дней');

  if (!term.from && !term.to) {
    return '';
  }

  if (term.from && term.to && term.from !== term.to) {
    return `от ${fromValue} до ${toValue} ${pluralizedToValue}`;
  }

  if (term.from) {
    return `от ${fromValue} ${pluralizedFromValue}`;
  }

  if (term.to) {
    return `до ${toValue} ${pluralizedToValue}`;
  }

  return '';
};

export const getRatePsk = (card: IClientMicrocredit) => {
  switch (true) {
    case !isNil(card.ratePskFrom) && !isNil(card.ratePskTo) && card.ratePskFrom !== card.ratePskTo:
      return `ПСК ${card.ratePskFrom} – ${card.ratePskTo}% в год`;

    case !isNil(card.ratePskFrom):
      return `ПСК ${card.ratePskFrom}% в год`;

    case !isNil(card.ratePskTo):
      return `ПСК ${card.ratePskTo}% в год`;

    default:
      return '';
  }
};

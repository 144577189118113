import cn from 'classnames';
import React, { memo, useMemo } from 'react';

import type { IConfig } from '@sravni/types/lib/branding';

import { useShowBottomBannerOnScroll } from '@src/hooks/useShowBottomBannerOnScroll';

import styles from './styles.module.scss';

interface IProps {
  className?: string;
  brandingConfig?: IConfig['creditCalculator'];
}

export const PageHeaderBranding: React.FC<IProps> = memo(({ brandingConfig, children, className }) => {
  const { scrollRef } = useShowBottomBannerOnScroll();

  const image = brandingConfig?.backgroundImage?.original;

  const { wrapperStyles, backgroundStyles } = useMemo(
    () => ({
      wrapperStyles: {
        '--height': image ? '310px' : 'auto',
        '--background-color': brandingConfig?.backgroundColor,
      } as React.CSSProperties,
      backgroundStyles: {
        '--background-image': image && `url("${image}")`,
        '--main-text-color': brandingConfig?.mainTextColor,
      } as React.CSSProperties,
    }),
    [brandingConfig?.backgroundColor, image, brandingConfig?.mainTextColor],
  );

  return (
    <div ref={scrollRef} className={cn(styles.page_header_branding, className)}>
      <div className={cn(styles.page_header_branding_background_wrapper)} style={wrapperStyles}>
        <div
          className={cn(styles.page_header_branding_background, {
            [styles.backgroundImage]: image,
          })}
          style={backgroundStyles}
        />
      </div>

      {children}
    </div>
  );
});

import React, { memo, useCallback, useMemo } from 'react';

import { Button, Dialog, Grid, Select, Switch } from '@sravni/react-design-system';
import type { SelectValue } from '@sravni/react-design-system/dist/types/components/Select/types/Select.types';
import { useIsMobile } from '@sravni/react-utils';

import type * as Microcredits from '@src/@types/microcredits';
import { PRODUCT_LIST_ANCHOR } from '@src/constants/common';
import { FILTERS_DICTIONARIES_KEYS } from '@src/constants/dictionaries';
import { FILTERS_NAMES } from '@src/constants/filters';
import { scrollToElementById } from '@src/helpers/common';
import { getDictionariesFieldsForSelect } from '@src/helpers/dictionaries';
import { useCreditsExtraFiltersVisibility } from '@src/hooks/useCreditsExtraFiltersVisibility';
import { useDictionaries } from '@src/reducers/dictionaries';

import styles from './styles.module.scss';

interface IProps {
  filters?: Microcredits.IFilters;
  onChange: (value: any, name: string) => void;
  onClickResetButton: () => void;
  className?: string;
}

export const PageHeaderFiltersExtra: React.FC<IProps> = memo(({ filters, onChange, onClickResetButton, className }) => {
  const isMobile = useIsMobile();
  const [conditionsActive, setConditionsActive] = useCreditsExtraFiltersVisibility();
  const dictionaries = useDictionaries();
  const handleToggleConditionDialog = () => {
    setConditionsActive(!conditionsActive);
  };

  const handleClickMobileSelectionButton = () => {
    setConditionsActive(!conditionsActive);
    scrollToElementById(PRODUCT_LIST_ANCHOR);
  };
  const [registrationWayOptions, benefitsOptions, documentsOptions] = useMemo(
    () => [
      getDictionariesFieldsForSelect(dictionaries, FILTERS_DICTIONARIES_KEYS.REGISTRATION_WAY),
      getDictionariesFieldsForSelect(dictionaries, FILTERS_DICTIONARIES_KEYS.BENEFITS),
      getDictionariesFieldsForSelect(dictionaries, FILTERS_DICTIONARIES_KEYS.DOCUMENTS),
    ],
    [dictionaries],
  );

  const handleChangeFilter = useCallback((name: FILTERS_NAMES) => (value?: SelectValue) => onChange(value, name), [
    onChange,
  ]);
  const handleChangeRadio = useCallback(
    (name: FILTERS_NAMES) => (event: React.FormEvent<HTMLInputElement>) => onChange(event.currentTarget.checked, name),
    [onChange],
  );

  const gutter: [number, number] = isMobile ? [0, 16] : [40, 20];
  const spanValue = isMobile ? 12 : 6;

  return (
    <Dialog
      visible={conditionsActive}
      fullscreen={isMobile}
      onClose={handleToggleConditionDialog}
      className={className}
    >
      <Dialog.Header title="Параметры займа" />
      <Dialog.Content>
        <Grid.Row className={styles.dialog_content} gutter={gutter}>
          <Grid.Col span={spanValue}>
            <Select
              label="Оформление"
              // @ts-ignore
              value={filters?.[FILTERS_NAMES.REGISTRATION_WAY] || null}
              options={registrationWayOptions}
              onChange={handleChangeFilter(FILTERS_NAMES.REGISTRATION_WAY)}
            />
          </Grid.Col>
          <Grid.Col span={spanValue}>
            <Select
              label="Особенности"
              value={filters?.[FILTERS_NAMES.BENEFITS] || []}
              mode="multiple"
              options={benefitsOptions}
              onChange={handleChangeFilter(FILTERS_NAMES.BENEFITS)}
            />
          </Grid.Col>
          {!isMobile ? (
            <>
              <Grid.Col className={styles.radio_container} span={spanValue}>
                <Switch
                  className={styles.radio}
                  name="1"
                  checked={filters?.[FILTERS_NAMES.PROLONGATION]}
                  onChange={handleChangeRadio(FILTERS_NAMES.PROLONGATION)}
                >
                  Пролонгация
                </Switch>
                <Switch
                  className={styles.radio}
                  name="2"
                  checked={filters?.[FILTERS_NAMES.POSTPONEMENT]}
                  onChange={handleChangeRadio(FILTERS_NAMES.POSTPONEMENT)}
                >
                  Отсрочка
                </Switch>
              </Grid.Col>
              <Grid.Col span={spanValue}>
                <Select
                  label="Документы"
                  mode="multiple"
                  value={filters?.[FILTERS_NAMES.DOCUMENTS] || []}
                  options={documentsOptions}
                  onChange={handleChangeFilter(FILTERS_NAMES.DOCUMENTS)}
                />
              </Grid.Col>
            </>
          ) : (
            <Grid.Col className={styles.radio_container} span={spanValue}>
              <Switch
                className={styles.radio}
                name="1"
                checked={filters?.[FILTERS_NAMES.PROLONGATION]}
                onChange={handleChangeRadio(FILTERS_NAMES.PROLONGATION)}
              >
                Пролонгация
              </Switch>
              <Switch
                className={styles.radio}
                name="2"
                checked={filters?.[FILTERS_NAMES.POSTPONEMENT]}
                onChange={handleChangeRadio(FILTERS_NAMES.POSTPONEMENT)}
              >
                Отсрочка
              </Switch>
            </Grid.Col>
          )}
        </Grid.Row>
      </Dialog.Content>
      <Dialog.Footer className={styles.dialog_footer}>
        <Button className={styles.footer_button} color="gray" variant="secondary" onClick={onClickResetButton}>
          Очистить фильтр
        </Button>
        <Button className={styles.footer_button} variant="primary" onClick={handleClickMobileSelectionButton}>
          Показать
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
});

import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useIsMobile } from '@sravni/react-utils';
import type { Seo } from '@sravni/types/lib/seo';

import { DEFAULT_FILTERS, MODES } from '@src/constants/filters';
import { ListKeys } from '@src/constants/microcredits';
import { useFilter } from '@src/hooks/useFilters';
import { useCurrentLocation } from '@src/reducers/locations';
import { getCreditsMetadata } from '@src/reducers/microcredits';
import { useHasUtmLabel } from '@src/reducers/route';
import { useSeoState } from '@src/reducers/seo';
import { createReplacements, replaceMetadata } from '@src/utils/meta';
import { isMainPage } from '@src/utils/routing';

const DEFAULT_PAGE_FILTERS = {
  [ListKeys.LIST]: DEFAULT_FILTERS[MODES.DEFAULT],
  [ListKeys.CALCULATOR]: DEFAULT_FILTERS[MODES.CALCULATOR],
  [ListKeys.ONLINE]: DEFAULT_FILTERS[MODES.ONLINE],
};

export const useListPage = (listKey: ListKeys) => {
  const isMobile = useIsMobile();
  const { asPath, query } = useRouter();
  const isMainMicroCreditsPage = isMainPage(asPath, query);
  const defaultFilters = DEFAULT_PAGE_FILTERS[listKey as keyof typeof DEFAULT_PAGE_FILTERS];

  const { filters: seoFilters = {}, seo, pathname } = useSeoState();
  const hasUtmLabel = useHasUtmLabel();

  const currentLocation = useCurrentLocation();
  const metadata = useSelector(getCreditsMetadata);

  const replacements = useMemo(() => createReplacements(metadata, currentLocation), [metadata, currentLocation]);

  const seoWithReplacements = useMemo(() => replaceMetadata(seo as Seo, replacements), [seo, replacements]);

  const filter = useFilter({
    name: listKey,
    defaultValue: {
      ...(defaultFilters ?? {}),
      ...seoFilters,
      ...(isMobile && { limit: defaultFilters?.limitMobile }),
    },
  });

  return {
    filter,
    seo: seoWithReplacements,
    pathname,
    isMainMicroCreditsPage,
    hasUtmLabel,
    currentLocation,
  };
};

import cn from 'classnames';
import type { FC } from 'react';
import React from 'react';

import { AmountControl } from '@src/components/ui/AmountControlAB';
import { FILTERS_NAMES } from '@src/constants/filters';
import { AMOUNT_LABEL, AMOUNT_POSTFIX, AMOUNT_TEXT } from '@src/constants/fullDeal';

import { HelperText } from '../../../../HelperText';
import { AMOUNT_BADGE_LIST } from '../../../consts';
import styles from '../../../styles.module.scss';

type Props = {
  amount: number;
  error: string;
  onChange(value: number): void;
};

export const AmountSheetContent: FC<Props> = ({ amount, error, onChange }) => (
  <>
    <div>
      <AmountControl
        name={FILTERS_NAMES.AMOUNT}
        label={AMOUNT_LABEL}
        value={amount}
        onChange={onChange}
        postfix={AMOUNT_POSTFIX}
      />
      <HelperText error={error} text={AMOUNT_TEXT} />
    </div>

    <div className={styles.tagContainer}>
      {AMOUNT_BADGE_LIST.map(({ label, value }) => (
        <div
          key={value}
          onClick={() => onChange(value)}
          className={cn(styles.badge, {
            [styles.inactive]: amount !== value,
            [styles.active]: amount === value,
          })}
        >
          {label}
        </div>
      ))}
    </div>
  </>
);

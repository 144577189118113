interface Options {
  symbol?: string;
  withSymbol?: boolean;
}

const DEFAULT_OPTIONS = {
  symbol: '₽',
  withSymbol: true,
};

export const formatMoney = (value: number | string, options?: Options): string => {
  const _options = {
    ...DEFAULT_OPTIONS,
    ...options,
  };

  // eslint-disable-next-line @typescript-eslint/init-declarations
  let num: number;

  switch (typeof value) {
    case 'string':
      num = parseFloat(value);
      break;
    case 'number':
      num = value;
      break;
    default:
      num = 0;
  }

  if (Number.isNaN(num)) {
    num = 0;
  }

  const str: string[] = num.toLocaleString('ru').split(',');

  if (str[1] && str[1].length === 1) {
    str[1] = `${str[1]}0`;
  }

  if (str[1] && str[1].length > 2) {
    str[1] = str[1].substr(0, 2);
  }

  let formattedMoney = str.join(',').replace(/\s/g, '\u00A0');

  if (_options.withSymbol) {
    formattedMoney += `\u00A0${_options.symbol}`;
  }

  return formattedMoney;
};

import cn from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useIsMobile } from '@sravni/react-utils';
import type { IConfig } from '@sravni/types/lib/branding';

import { PIXELS_TYPES } from '@src/constants/analytics';
import { sendSponsorClickEvent } from '@src/helpers/analyticsEvents';
import { usePixel } from '@src/hooks/usePixel';
import { useBranding, usePixelsBranding } from '@src/reducers/branding';

import styles from './styles.module.scss';

interface IProps {
  className?: string;
  brandingConfig?: IConfig['creditCalculator'];
}

export const BrandingMobile: React.FC<IProps> = ({ className, brandingConfig }) => {
  const [isReady, setIsReady] = useState(false);
  const { organization } = useBranding() || {};
  const link = usePixel(usePixelsBranding()?.click, {
    pixelType: PIXELS_TYPES.CLICK,
  });
  const isMoneyMan = organization?.name === 'moneyman';
  const img = useRef<HTMLImageElement>();
  const backgroundImage = brandingConfig?.backgroundImage?.original;
  const isMobile = useIsMobile();

  const componentStyles = useMemo(
    () =>
      ({
        '--background-image': `url(${backgroundImage})`,
      } as React.CSSProperties),
    [backgroundImage],
  );

  useEffect(() => {
    if (!img.current) {
      img.current = new Image();
      img.current.onload = () => {
        setIsReady(true);
      };
    }

    if (backgroundImage) {
      img.current.src = `${backgroundImage}?`;
    }

    return () => {
      if (img.current) {
        img.current.onload = (f) => f;
      }
    };
  }, [backgroundImage]);

  const handleClickBrandingLink = useCallback(() => {
    sendSponsorClickEvent(organization?.name);

    if (link) {
      window.open(link, '_blank', 'noopener');
    }
  }, [organization?.name, link]);

  if (isMoneyMan || isMobile) {
    return null;
  }

  return (
    <div
      className={cn(styles.container, className, { [styles.backgroundImage]: backgroundImage })}
      style={componentStyles}
      onClick={handleClickBrandingLink}
    >
      <img className={styles.image} hidden={!isReady} src={backgroundImage} />
    </div>
  );
};

import cn from 'classnames';
import has from 'lodash/has';
import { useRouter } from 'next/router';
import React, { memo } from 'react';

import { Grid } from '@sravni/react-design-system';
import { useIsMobile } from '@sravni/react-utils';
import type { Seo } from '@sravni/types/lib/seo';

import type * as Microcredits from '@src/@types/microcredits';
import {
  PageHeaderBranding as PageHeaderBrandingBase,
  PageHeaderBrandingContainer,
  PageHeaderLocation,
  PageHeaderRegionsDialog,
  PageHeaderSponsorLink,
  PageHeaderSubHeading,
  useRegionDialogToggle,
} from '@src/components/PageHeader';
import { useHeadingTexts } from '@src/components/PageHeader/hooks';
import { useDeviceBranding } from '@src/reducers/branding';
import { isNewSpecialPage, isPartner2GisPage, isSpecialPageWithoutQuery } from '@src/utils/routing';

import { PageHeaderBrandingFilters } from './components';
import styles from './styles.module.scss';

interface IProps {
  filters: Microcredits.IFilters;
  difference?: number;
  onChange: (value: any, name: string) => void;
  seo?: Partial<Seo>;
}

export const PageHeaderBranding: React.FC<IProps> = memo((filtersProps) => {
  const isMobile = useIsMobile();
  const { asPath, query } = useRouter();
  const { creditCalculator } = useDeviceBranding() || {};
  const { isOpenedDialog, closeRegionDialog, toggleRegionDialog } = useRegionDialogToggle();
  const { headingText, subHeadingText } = useHeadingTexts();

  const isSpecial = isSpecialPageWithoutQuery(asPath);
  const isNewSpecial = isNewSpecialPage(asPath);
  const isCRMSpecial = isSpecial && has(query, 'landing_type');
  const isPartner2Gis = isPartner2GisPage(asPath);

  const isShowFilters = !isCRMSpecial && !isNewSpecial;

  const hasBanner = !!creditCalculator?.backgroundImage?.original;

  if (isMobile) {
    return (
      <>
        {isShowFilters && <PageHeaderBrandingFilters {...filtersProps} hasBanner={hasBanner} />}

        <div className={styles.hidden_heading_section}>
          <h1>{headingText}</h1>
          <div>{subHeadingText}</div>
        </div>
      </>
    );
  }

  return (
    <PageHeaderBrandingBase brandingConfig={creditCalculator}>
      <PageHeaderBrandingContainer className={cn(styles.page_header_branding_container)}>
        {!isNewSpecial && !isPartner2Gis && (
          <Grid.Row className={cn({ [styles.with_image]: hasBanner })}>
            <Grid.Col className={styles.location_container}>
              <PageHeaderLocation
                className={cn(styles.location, { [styles.location_with_image]: hasBanner })}
                brandingConfig={creditCalculator}
                position="center"
                header={headingText}
                onClick={toggleRegionDialog}
              />
              <PageHeaderSubHeading>{subHeadingText}</PageHeaderSubHeading>
            </Grid.Col>
            <Grid.Col className={styles.sponsor_container}>
              <PageHeaderSponsorLink />
            </Grid.Col>
          </Grid.Row>
        )}

        {isShowFilters && <PageHeaderBrandingFilters {...filtersProps} hasBanner={hasBanner} />}
      </PageHeaderBrandingContainer>
      <PageHeaderRegionsDialog isOpen={isOpenedDialog} onClose={closeRegionDialog} />
    </PageHeaderBrandingBase>
  );
});

import random from 'lodash/random';

import {
  BEST_RATING_VALUE,
  MAX_RATING_VALUE,
  MAX_REVIEW_COUNT_VALUE,
  MIN_RATING_VALUE,
  MIN_REVIEW_COUNT_VALUE,
  PRODUCT_TEMPLATE,
  WORST_RATING_VALUE,
} from '@src/components/Seo/consts';

export const getProductTemplate = () => {
  const randomRatingValue = random(MIN_RATING_VALUE, MAX_RATING_VALUE).toFixed(1).replace('.', ',');
  const roundedRatingValue = randomRatingValue === '5,0' ? '5' : randomRatingValue;
  const randomReviewCount = random(MIN_REVIEW_COUNT_VALUE, MAX_REVIEW_COUNT_VALUE).toString();

  return {
    ...PRODUCT_TEMPLATE,
    aggregateRating: {
      '@type': 'aggregateRating',
      ratingValue: roundedRatingValue,
      bestRating: BEST_RATING_VALUE,
      worstRating: WORST_RATING_VALUE,
      reviewCount: randomReviewCount,
    },
  };
};

import type { FC } from 'react';
import { useCallback, useEffect } from 'react';

import { Select } from '@sravni/react-design-system/lib/Select';

import type * as Microcredits from '@src/@types/microcredits';
import { FILTERS_NAMES } from '@src/constants/filters';
import { TERM_MONTH_OPTIONS } from '@src/constants/fullDeal';

type Props = {
  filters: Microcredits.IFilters;
  onChange: (value: any, name: string) => void;
  analyticsEvent?: VoidFunction;
};

export const TermDropdown: FC<Props> = ({ filters, onChange, analyticsEvent }) => {
  const termFilterValue = filters[FILTERS_NAMES.TERM];
  const selectedOption = TERM_MONTH_OPTIONS.find((option) => option.value === termFilterValue);

  useEffect(() => {
    if (termFilterValue && !selectedOption) {
      onChange('', FILTERS_NAMES.TERM);
    }
  }, [onChange, selectedOption, termFilterValue]);

  const handleTermChange = useCallback(
    (newTerm: string) => {
      analyticsEvent?.();
      onChange(newTerm, FILTERS_NAMES.TERM);
    },
    [analyticsEvent, onChange],
  );

  return (
    <Select
      label="На срок"
      value={selectedOption?.value ?? ''}
      options={TERM_MONTH_OPTIONS}
      onChange={handleTermChange}
      optionsSort={false}
    />
  );
};

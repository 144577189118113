import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { preparePath } from '@src/utils/routing';

import { sendExtraFiltersOpenEvent } from '../helpers/analyticsEvents';
import { setExtraFiltersVisibility, useSelectCreditsExternalFiltersVisibility } from '../reducers/microcredits';

export const useCreditsExtraFiltersVisibility = (): [boolean, (visibilityFlag: boolean) => void] => {
  const visibility = useSelectCreditsExternalFiltersVisibility();
  const dispatch = useDispatch();
  const { asPath } = useRouter();
  const pathname = preparePath(asPath);

  useEffect(() => {
    dispatch(setExtraFiltersVisibility(false));
  }, [pathname, dispatch]);

  return [
    visibility,
    (visibilityFlag) => {
      if (visibilityFlag) {
        sendExtraFiltersOpenEvent();
      }

      dispatch(setExtraFiltersVisibility(visibilityFlag));
    },
  ];
};

import cn from 'classnames';
import * as React from 'react';

import { useIsMobile } from '@sravni/react-utils';
import type { Seo } from '@sravni/types/lib/seo';

import { BrandingMobile, PageHeaderFiltersExtra } from '@src/components/PageHeader';
import { usePageContext } from '@src/config/PageContext';
import { FILTERS_NAMES } from '@src/constants/filters';
import type { IFilter } from '@src/hooks/useFilters';
import { useMobileBranding } from '@src/reducers/branding';

import { PageHeaderBranding } from './PageHeaderBranding';
import styles from './styles.module.scss';

interface IPageHeaderProps {
  className?: string;
  filter: IFilter;
  seo?: Partial<Seo>;
}

export const PageHeader: React.FC<IPageHeaderProps> = React.memo(({ className, filter, seo }) => {
  const isMobile = useIsMobile();
  const { extraFiltersVisible } = usePageContext();
  const { filters, setFilter, resetFilters, difference } = filter;
  const { creditCalculator } = useMobileBranding() || {};

  const handleChangeFilters = (value: any, name: string) => {
    const isValueToString = [FILTERS_NAMES.AMOUNT, FILTERS_NAMES.TERM].includes(name as FILTERS_NAMES);
    const newValue = isValueToString ? value || '' : value;
    setFilter(name, newValue);
  };

  return (
    <section className={cn(styles.page_header, className)}>
      {isMobile && <BrandingMobile brandingConfig={creditCalculator} />}
      <PageHeaderBranding difference={difference} filters={filters} onChange={handleChangeFilters} seo={seo} />
      {extraFiltersVisible && (
        <PageHeaderFiltersExtra filters={filters} onChange={handleChangeFilters} onClickResetButton={resetFilters} />
      )}
    </section>
  );
});

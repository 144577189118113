import { memo } from 'react';

import { FILTERS_NAMES } from '@src/constants/filters';
import { ListKeys } from '@src/constants/microcredits';
import { SortPanel } from '@src/containers/list/ProductList/SortPanel';
import { useLandingPage } from '@src/hooks/useLandingPage';

import styles from './styles.module.scss';

type Props = {
  amountValue: string;
  termValue: string;
  handleOpenSheet: (value: FILTERS_NAMES.AMOUNT | FILTERS_NAMES.TERM) => void;
};

export const HeaderControl = memo(({ amountValue, termValue, handleOpenSheet }: Props) => {
  const { filter } = useLandingPage(ListKeys.LIST);

  return (
    <div className={styles.mobileHeader}>
      <div className={styles.badgeContainer}>
        <div onClick={() => handleOpenSheet(FILTERS_NAMES.AMOUNT)} className={styles.badge}>
          {amountValue}
        </div>
        <div onClick={() => handleOpenSheet(FILTERS_NAMES.TERM)} className={styles.badge}>
          {termValue}
        </div>
      </div>
      <span className={styles.badge}>
        <SortPanel className={styles.sortWrapper} filter={filter} hasSortLabel={false} />
      </span>
    </div>
  );
});
